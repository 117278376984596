import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { 
  fetchActivities,
  selectLoadStatusForDay,
  selectActivitiesForCurrentDay,
  getActivityDomainForId,
  getRootActivityDomainNameForId,
} from '../features/activities/activitySlice';

import { closePopUp, openNewActivityPopUp, openEditActivityPopUp } from '../features/uiState/uiSlice';

import { Activity, ActivityDomain, RestEndpointState } from '../_types';
import { useSelector } from 'react-redux';

import { DayHeader } from '../partials/DayHeader';
import NewActivityPopUp from '../features/logging/NewActivityPopUp';
import EditActivityPopUp from '../features/logging/EditActivityPopUp';

interface DayProps {
  dayStr?: string 
}

const timeFormatArgs: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

const ActivityBox = (props: Activity) => {
  const dispatch = useAppDispatch();

  const ad = useSelector(getActivityDomainForId(props.activityDomainId as number));
  const rd = useSelector(getRootActivityDomainNameForId(props.activityDomainId as number)); 

  const st = props.startTime as number
  const startTime: Date = new Date(st * 1000)
  const et = props.endTime as number
  const endTime: Date = new Date(et * 1000)

  const mh = ( props.durationInSeconds as number ) / 100

  const extraCssClass = rd ? rd.name.toLowerCase() : ''

  const handleEditClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(openEditActivityPopUp(props.id));
  }

  return (
    <div 
      className={`flex justify-between p-1 ${extraCssClass}`}
      style={{ minHeight: `${mh}px` }}
    >
      <div>
        {startTime.toLocaleTimeString(undefined, timeFormatArgs)} -
        {endTime.toLocaleTimeString(undefined, timeFormatArgs)} &emsp;
        {ad && ad.name}
      </div>
      
      <div className="">
        <a href="#" onClick={handleEditClick}>(edit)</a>
      </div>
    </div>
  )
}

interface ActivityLogProps {
  activities: Array<Activity>
}
const ActivityLog = (props: ActivityLogProps) => {
  const as: Array<Activity> = [...props.activities].reverse();

  return (
    <div className='overflow-y-scroll'>
      {as.map((activity: Activity, i) => { return <ActivityBox key={i} {...activity} /> })}
    </div>
  )
}

const PieChart = () => {
  return (
    <>
      <div className='grow-0 text-right'>
      
      </div>
      <div className='grow-1 flex flex-col justify-center align-middle'>
        <p>Pie Chart goes here</p>
      </div>
    </>
  )
}

const Day = () => {
  const dispatch = useAppDispatch();

  const openPopUp = useAppSelector((state) => state.ui.openPopUp);
  const activeDateStr = useAppSelector((state) => state.ui.currentDateStr);
  const activeActivityId = useAppSelector((state) => state.ui.activeActivityId);
  const activities: Array<Activity> = useAppSelector(selectActivitiesForCurrentDay);
  const loadStatus: string = useAppSelector( selectLoadStatusForDay(activeDateStr) ); 

  const curDate = new Date(activeDateStr);
  const shouldLoad = (loadStatus === 'idle');

  const isPopupOpen = openPopUp !== null;
  const isNewActivityPopUpOpen = openPopUp === 'newActivity';
  const isEditActivityPopUpOpen = openPopUp === 'editActivity' && activeActivityId !== null;

  useEffect(() => {
    if (shouldLoad) {
      dispatch(fetchActivities({ day: activeDateStr }));
    }
  }, [loadStatus, dispatch]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !isPopupOpen) {
        dispatch(openNewActivityPopUp());
      } else if (event.key === 'Escape' && isPopupOpen) {
        dispatch(closePopUp());
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [dispatch, isPopupOpen, isNewActivityPopUpOpen]);

  return(
    <>
      <DayHeader date={curDate} />
      
      <div className='overflow-hidden flex'>
        <div className='hidden md:flex w-1/2 h-full bg-gray-3 flex-col align-middle p-4'>
          <PieChart />
        </div>

        <div className='w-full md:w-1/2 h-full bg-gray-6 flex flex-col justify-center align-middle p-4 overflow-hidden'>
          <ActivityLog activities={activities} />
        </div>

        {isNewActivityPopUpOpen  && <NewActivityPopUp />}
        {isEditActivityPopUpOpen && <EditActivityPopUp activityId={activeActivityId} />}
      </div>
    </>
  )
}
export default Day 