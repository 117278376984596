import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentDateStr } from '../features/uiState/uiSlice';

import { fetchDomains } from '../features/activities/activitySlice';

import { DayHeader } from '../partials/DayHeader';

export default function Tracking() {
  const dispatch = useAppDispatch();

  let { dateStr } = useParams();
  const activeDateStr = useAppSelector((state) => state.ui.currentDateStr);
  
  if (dateStr && (dateStr !== activeDateStr)) {
    dispatch(setCurrentDateStr(dateStr));
  }
  
  const domainLoadStatus: string = useAppSelector(state => state.activity.domains.fetchState.status);
  useEffect(() => {
    console.log('domainLoadStatus: ', domainLoadStatus);
    if (domainLoadStatus === 'idle') {
      dispatch(fetchDomains());
    } else {
      console.log("Not fetchin'");
    }
  }, [domainLoadStatus, dispatch]);

  return (
    <div className='w-screen h-screen flex flex-col overflow-hidden text-center'>
      <Outlet />
    </div>
  );
}