import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../app/hooks'; 

import { Activity, ActivityDomain, RestEndpointState } from '../../_types';

import { parseDateStr } from '../../util/dateTimeFormatting';
import Select from 'react-select';
import { SingleValue, ActionMeta } from 'react-select';

import {
  selectActivityById, 
  updateActivity,
  getActivityDomainsForSelect,
  getActivityDomainForId,
  getMostRecentActivityForDay
} from '../activities/activitySlice';

import { closePopUp } from '../uiState/uiSlice';

import { formatTime24HourClock } from '../../util/dateTimeFormatting';
import { act } from 'react-dom/test-utils';

export const EditActivityPopUp = (props: { activityId: number }) => {
  const dispatch = useAppDispatch();
 
  const activity = useAppSelector(selectActivityById(props.activityId));
  const domain = useAppSelector(getActivityDomainForId(activity?.activityDomainId as number));
  const activeDateStr = useAppSelector((state) => state.ui.currentDateStr);
  const domainsForSelect = useAppSelector(getActivityDomainsForSelect);

  const firstFormFieldRef = useRef<HTMLInputElement>(null);
  // const firstFormFieldRef = useRef<StateManagedSelect>(null);

  const dropshadowStyle = "drop-shadow(2px 2px 12px #999)";

  const mrafd = useAppSelector(getMostRecentActivityForDay(activeDateStr as string));
  const curTime = new Date();
  const prevTime = mrafd?.endTime ? new Date((mrafd.endTime as number) * 1000 ) : curTime;
  
  // if (!activity) { return null; }

  const startTimeMilliseconds = activity?.startTime ? ( activity.startTime as number * 1000 ) : prevTime.getTime();
  const startTimeDate = new Date(startTimeMilliseconds);
  const endTimeMilliseconds = activity?.endTime ? ( activity.endTime as number * 1000 ) : curTime.getTime();
  const endTimeDate   = new Date(endTimeMilliseconds);
  
  const [startTime, setStartTime] = useState(startTimeMilliseconds);
  const [startTimeStr, setStartTimeStr] = useState(formatTime24HourClock(startTimeDate));
  const [endTime, setEndTime] = useState(endTimeMilliseconds);
  const [endTimeStr, setEndTimeStr] = useState(formatTime24HourClock(endTimeDate));

  const selectedValue = domain ? { value: domain.id.toString(), label: domain.name } : null;
  const [domainId, setDomainId] = useState(activity?.activityDomainId?.toString() || '');

  const handleStartTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const t = parseDateStr(activeDateStr, val);
    setStartTime(t.getTime());
    setStartTimeStr(val);
  }
  const handleEndTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const t = parseDateStr(activeDateStr, val);
    setEndTime(t.getTime());
    setEndTimeStr(val);
  }

  const handleBgClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(closePopUp());
  }
  const handleNestedClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }

  // TODO
  const handleSelectChange: (
    newValue: SingleValue<{ value: string; label: string; }>, 
    actionMeta: ActionMeta<{ value: string; label: string; }>
  ) => void = (selected, actionMeta) => {
    if (selected) {
      setDomainId(selected.value)
    } else {
      setDomainId('')
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const activityDomainId = parseInt(domainId);
    console.log('submit');
    if (!activity) { return; } else {
      dispatch(updateActivity({
        id: props.activityId,
        activityDomainId: activityDomainId,
        startTime: startTime,
        endTime: endTime,
        day: activeDateStr as string 
      }));
    }
    dispatch(closePopUp());
  }

  if (!activity) { 
    return null; 
  } else {
    return (
      <div className='fixed inset-0 flex justify-center items-center' onClick={handleBgClick}>
        <div className='bg-gray-0 p-12' style={{filter: dropshadowStyle}} onClick={handleNestedClick}>
          <div className='mb-4'>
            <h2 className='text-xl'>Editing activity</h2>
            <p style={{fontSize: '80%', color: '#888'}}>ID# {props.activityId}</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='mt-4 text-left'>
              <label htmlFor="activityStartTime" className='block mb-1'>Activity</label>
              <Select 
                  options={domainsForSelect}
                  defaultValue={selectedValue}
                  isClearable={true} 
                  onChange={handleSelectChange} 
                  menuPlacement={'bottom'}
                />
            </div>

            <div className='flex'>
              <div className='m-4 ml-0 text-left'>
                <label htmlFor="activityStartTime" className='block'>Start time</label>
                <input 
                  type="time" 
                  min="00:00" 
                  max="24:00" 
                  value={startTimeStr} 
                  onChange={handleStartTimeChanged} 
                  required
                  ref={firstFormFieldRef} 
                />
              </div>
              <div className='m-4 mr-0 text-left'>
                <label htmlFor="activityEndTime" className='block'>End time</label>
                <input type="time" min="00:00" max="24:00" value={endTimeStr} onChange={handleEndTimeChanged} required />
              </div>
            </div>
            
            <div className='my-4'>
              <input 
                type="submit" 
                name="commit"
                className="btn btn-border w-full py-3" 
                value="Create Journal activity" 
                // disabled={true} 
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default EditActivityPopUp;