import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Root routes
import Tracking from './routes/Tracking';
import Auth from './routes/Auth'

// Pages
import Day from './pages/Day';
import LoginPage from './pages/Login';

import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Tracking />,
    children: [
      { path: "/",              element: <Day /> },
      { path: "/day",           element: <Day /> },
      { path: "/day/:dateStr",  element: <Day /> },
    ],
    
  },
  {
    path: "/login",
    element: <Auth />,
    children: [
      { path: "/login",         element: <LoginPage /> },
    ],
  }
]);

export function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
