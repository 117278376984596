import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

import { config } from '../../config'

import { Activity, ActivityDomain } from '../../_types';

import { getToday, getDateStr } from '../../util/dateTimeFormatting';

export interface UIState {
  currentDateStr: string;
  openPopUp: string | null;
  activeActivityId: number | null;
}

const initialState: UIState = {
  openPopUp: null,
  currentDateStr: getDateStr(getToday()),
  activeActivityId: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCurrentDateStr: (state, action) => {
      state.currentDateStr = action.payload;
    },
    closePopUp: (state) => {
      state.openPopUp = null;
    },
    openNewActivityPopUp: (state) => {
      state.openPopUp = 'newActivity';
    },
    openEditActivityPopUp: (state, action) => {
      state.activeActivityId = action.payload;
      state.openPopUp = 'editActivity';
    },
  },
  
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchActivities.pending, (state) => {
  //       state.activities.fetchState = { status: 'pending' };
  //     })
  // },
})

export const {
  closePopUp,
  setCurrentDateStr,
  openNewActivityPopUp,
  openEditActivityPopUp
} = uiSlice.actions;
export default uiSlice.reducer