import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import activityReducer from '../features/activities/activitySlice';
import uiReducer from '../features/uiState/uiSlice';

export const store = configureStore({
  reducer: {
    auth:     authReducer,
    activity: activityReducer,
    ui:       uiReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
