import React from 'react';
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentDateStr } from '../features/uiState/uiSlice';
import { openNewActivityPopUp, closePopUp } from '../features/uiState/uiSlice';

import { 
  getDateStr, 
  getToday,
  getPreviousDay,
  getNextDay, 
} from '../util/dateTimeFormatting';

export const DayHeader = (props: { date: Date }) => {
  const dispatch = useAppDispatch();
  
  const today = getToday();
  const curDateStr = getDateStr(props.date);

  const prevDayStr  = getDateStr(getPreviousDay(props.date));
  const todayStr    = getDateStr(today);
  const nextDayStr  = getDateStr(getNextDay(props.date));

  const futureAvailable = (today > props.date);
  
  const handleNewActivityClick = () => {
    dispatch(openNewActivityPopUp());
  }

  return (
    // <div className='fixed top-0 left-0 right-0 flex justify-between text-2xl bg-gray-0 py-1'>
    <div className='flex gap-4 justify-between bg-gray-0 py-1'>
      <div className='grow flex justify-between'>
        <Link to={`/day/${prevDayStr}`}>&lang;</Link>
        
        <div className='md:w-48 flex justify-end items-center gap-4'>
          <Link to={`/day/${todayStr}`} className='btn btn-border text-sm'>
            Today
          </Link>
        </div>
      </div>
      
      <div className='md:w-40 flex flex-col justify-center'>
        <h1 className='md:text-2xl '>
          {curDateStr}
        </h1>
      </div>
      
      <div className='grow flex justify-between'>
        <div className='md:w-48 flex justify-start items-center gap-4'>
          <button className='btn btn-border text-sm' onClick={handleNewActivityClick}>New activity</button>
          <button className='hidden md:block btn btn-border text-sm'>Edit</button>
        </div>
        
        { futureAvailable ? 
          (<Link to={`/day/${nextDayStr}`}>&rang;</Link>) :
          (<div>&#10005;</div>)
        }
      </div>
    </div>
  )
}

export default DayHeader;