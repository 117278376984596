
export function getToday(): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
  return date;
}

export function getPreviousDay(date: Date): Date {
  const previousDate = new Date(date);
  previousDate.setDate(previousDate.getDate() - 1);
  return previousDate;
}

export function getNextDay(date: Date): Date {
  const nextDate = new Date(date);
  nextDate.setDate(nextDate.getDate() + 1);
  return nextDate;
}

export function getDateStr(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

export function formatTime24HourClock(date: Date): string {
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  return `${hour}:${minute}`;
}

export function parseDateStr(dateString: string, timeString?: string): Date {
  const [year, month, day] = dateString.split('-');
  let hours, minutes;
  if (timeString) {
    [hours, minutes] = timeString.split(':');
  } else {
    [hours, minutes] = ["00", "00"];
  }
  return new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes));
}