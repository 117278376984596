import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentDateStr } from '../features/uiState/uiSlice';

import { fetchDomains } from '../features/activities/activitySlice';

export default function Tracking() {
  const dispatch = useAppDispatch();

  return (
    <div className='w-screen h-screen flex flex-col justify-center items-center text-center'>
      <Outlet />
    </div>
  );
}