import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../app/hooks'; 


export const LoginPage = () => {
  const dispatch = useAppDispatch();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setEmail(val);
  }
  const handlePasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setPassword(val);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('submit');
  }

  return (
    <div className='' style={{width: '24rem'}} >
      <div className=''>
        <h1 className='text-xl'>Log it on in, Jack</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='flex flex-col'>
          <div className='my-4 text-left'>
            <label htmlFor="email" className='block'>Email</label>
            <input
              className='w-full' 
              type="email" 
              value={email}
              onChange={handleEmailChanged} 
              required
            />
          </div>

          <div className='my-4 text-left'>
            <label htmlFor="password" className='block'>Password</label>
            <input 
              className='w-full' 
              type="password" 
              value={password}
              onChange={handlePasswordChanged} 
              required
            />
          </div>
        </div>
        
        <div className='my-4'>
          <input 
            type="submit" 
            name="commit"
            className="btn btn-border w-full py-3" 
            value="Log in" 
          />
        </div>
      </form>
    </div>
  )
}

export default LoginPage;