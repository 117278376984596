import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

import { config } from '../../config'

import { RestEndpointState } from '../../_types';

import { getToday, getDateStr } from '../../util/dateTimeFormatting';
import { buildURLWithQueryParams } from '../../util/urlFormatting';

const fetchEndpointState: RestEndpointState = { status: 'idle' }
const postEndpointState:  RestEndpointState = { status: 'idle' }

export interface AuthState {
  loggedIn: boolean;
  apiState: {
    fetchState:  RestEndpointState;
    postState:   RestEndpointState;
    deleteState: RestEndpointState;
  }
  user: {
    email:      string | null;
    authToken:  string | null;
  }
}

const initialState: AuthState = {
  loggedIn: false,
  apiState: {
    fetchState:  { status: 'idle' },
    postState:   { status: 'idle' },
    deleteState: { status: 'idle' },
  },
  user: {
    email:      null,
    authToken:  null,
  }
};

export const readAuthToken = () => {
  const authToken = localStorage.getItem('authToken');
  return authToken;
}

export const writeAuthToken = (authToken: string) => {
  localStorage.setItem('authToken', authToken);
}

interface LoginArgs {
  email: string;
  password: string;
}
export const submitLogin = createAsyncThunk('auth/submitLogin', async (args: LoginArgs) => {
  const path = `${config.apiRoot}/sessions`

  const postData = {
    user: {
      email:    args.email,
      password: args.password,
    }
  }

  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(postData),
  })

  return await response.json()
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  },
  
  extraReducers: (builder) => {
    builder
      // .addCase(submitActivity.pending, (state) => {
      //   state.domains.fetchState = { status: 'pending' };
      // })
      .addCase(submitLogin.fulfilled, (state, action) => {
        const data = action.payload;
        state.user = data.user;
      })
      // .addCase(fetchDomains.rejected, (state) => {
      //   state.domains.fetchState = { status: 'error' };
      // })
  },
});


export const { 
} = authSlice.actions;
export default authSlice.reducer