import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

import { config } from '../../config'

import { Activity, ActivityDomain, RestEndpointState } from '../../_types';

import { getToday, getDateStr } from '../../util/dateTimeFormatting';
import { buildURLWithQueryParams } from '../../util/urlFormatting';

const fetchEndpointState: RestEndpointState = { status: 'idle' }
const postEndpointState:  RestEndpointState = { status: 'idle' }

interface SetOfActivitiesForDay {
  dateStr: string;
  fetchState: RestEndpointState;
  activities: Array<Activity>;
}

export interface ActivityState {
  days: Array<SetOfActivitiesForDay>
  domains: {
    fetchState: RestEndpointState;
    domains: Array<ActivityDomain>;
  }
}

const initialState: ActivityState = {
  days: [],
  domains: {
    fetchState: fetchEndpointState,
    domains: [],
  }
};

export const fetchDomains = createAsyncThunk('activity/fetchDomains', async () => {
  const url = `${config.apiRoot}/tracking/domains`
  
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return await response.json()
});

interface FetchArgs {
  day?: string;
}
export const fetchActivities = createAsyncThunk('activity/fetchActivities', async (args: FetchArgs) => {
  const baseUrl = `${config.apiRoot}/tracking/activities`
  const dayStr = args.day || getDateStr(getToday());
  const queryParams = { day: dayStr }
  
  const url = buildURLWithQueryParams(baseUrl, queryParams);
  
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return await response.json()
})

interface ActivityArgs {
  startTime: number;
  endTime: number;
  activityDomainId: number;
  day?: string;
}
export const submitActivity = createAsyncThunk('activity/submitActivity', async (args: ActivityArgs) => {
  const path = `${config.apiRoot}/tracking/activities`

  const postData = {
    activity: {
      start_time: args.startTime,
      end_time: args.endTime,
      activity_domain_id: args.activityDomainId,
      day: args.day || getDateStr(getToday()),
    }
  }

  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(postData),
  })

  return await response.json()
})

interface UpdateActivityArgs extends ActivityArgs {
  id: number;
}
export const updateActivity = createAsyncThunk('activity/updateActivity', async (args: UpdateActivityArgs) => {
  const path = `${config.apiRoot}/tracking/activities/${args.id}`

  const postData = {
    activity: {
      start_time: args.startTime,
      end_time: args.endTime,
      activity_domain_id: args.activityDomainId,
      day: args.day || getDateStr(getToday()),
    }
  }

  const response = await fetch(path, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(postData),
  })

  return await response.json()
})

export const fetchActiviyDomains = createAsyncThunk('activity/fetchActivityDomains', async () => {
  const path = `${config.apiRoot}/tracking/domains`
  const apiArgs = {}
  // const apiArgs = { errorLevel: args.errorLevel}
  
  const response = await fetch(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    // body: JSON.stringify(apiArgs),
  })

  return await response.json()
})

interface AuthArgs {
  email: string;
  password?: string;
}

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchDomains.pending, (state) => {
        state.domains.fetchState = { status: 'pending' };
      })
      .addCase(fetchDomains.fulfilled, (state, action) => {
        state.domains.fetchState = { status: 'success' };
        const data = action.payload;
        state.domains.domains = data.domains
      })
      .addCase(fetchDomains.rejected, (state) => {
        state.domains.fetchState = { status: 'error' };
      })
      
      // .addCase(submitActivity.pending, (state) => {
      //   state.domains.fetchState = { status: 'pending' };
      // })
      .addCase(submitActivity.fulfilled, (state, action) => {
        const data = action.payload;
        data.activities.forEach((activity: Activity) => { 
          addActivity(state, activity); 
        });
      })
      // .addCase(fetchDomains.rejected, (state) => {
      //   state.domains.fetchState = { status: 'error' };
      // })
      .addCase(updateActivity.fulfilled, (state, action) => {
        const data = action.payload;
        data.activities.forEach((activity: Activity) => { 
          addActivity(state, activity); 
        });
      })

      .addCase(fetchActivities.pending, (state) => {
        // state.activities.fetchState = { status: 'pending' };
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        // state.activities.fetchState = { status: 'success' };
        const data = action.payload;
        console.log("data: ");
        console.log(data);

        // data.activities.forEach((activity: Activity) => { addActivity(activity); });
        // state.activities.activities = data.activities
        data.activities.forEach((activity: Activity) => { 
          addActivity(state, activity); 
        });
      })
      .addCase(fetchActivities.rejected, (state) => {
        // state.activities.fetchState = { status: 'error' };
      });
  },
});

// export const getProjectElementById = (state: RootState, elId: number) => {
//   let projectElement: PageElement | null;
//   const { projects } = state.projects;
//   const allProjects = Object.values(projects).flat();
//   const allPageElements = allProjects.reduce((acc, project) => {
//     project.pageElements.forEach(pe => acc.push(pe));
//     return acc; // Return the accumulator
//   }, [] as PageElement[]); // Initialize the accumulator as an empty array of PageElement
//   projectElement = allPageElements.find((element) => element.id === elId) || null;
  
//   return ( projectElement );
// }
export const selectActivityById = (id: number) => (state: RootState) => {
  let activity: Activity | null;
  const { days } = state.activity;
  const allActivities = days.reduce((acc: Activity[], day: SetOfActivitiesForDay) => {
    day.activities.forEach(a => acc.push(a));
    return acc; // Return the accumulator
  }, [] as Activity[]); // Initialize the accumulator as an empty array of Activity
  activity = allActivities.find((a: Activity) => a.id === id) || null;

  return ( activity );
}

export const addActivity = (state: ActivityState, activity: Activity) => {
  console.log("addActivity: ");
  console.log(activity);
  const oldDays = state.days;
  let newDays: Array<SetOfActivitiesForDay>;
  let day = oldDays.find((d: SetOfActivitiesForDay) => { return ( d.dateStr === activity.day)});
  if (typeof(day) === 'undefined') {
    day = {
      dateStr: activity.day,
      fetchState: { status: 'success' },
      activities: [activity]
    }
    oldDays.push(day);
  } else {
    let extantActivity = day.activities.find((a: Activity) => { return ( a.id === activity.id )});
    if (typeof(extantActivity) === 'undefined') {
      day.activities.push(activity);
    } else {
      day.activities = day.activities.map((a: Activity) => { return ( a.id === activity.id ? activity : a )});
    }
  }
    
  newDays = oldDays;
  state.days = newDays;
}

export const selectLoadStatusForDay = (dayStr: string) => (state: RootState) => {
  const day = state.activity.days.find((d: SetOfActivitiesForDay) => { return ( d.dateStr === dayStr)});
  const ls = day ? day.fetchState.status : 'idle' 
  
  return(ls);
}

export const selectActivitiesForCurrentDay = (state: RootState) => {
  const currentDateStr = state.ui.currentDateStr;
  // const as = state.activity.activities.activities.filter((a: activity) => { return ( a.day === currentdatestr )})
  const day = state.activity.days.find((d: SetOfActivitiesForDay) => { return ( d.dateStr === currentDateStr)});
  const as = day ? day.activities : []
  
  return(as);
}

export const getMostRecentActivityForDay = (dayStr: string) => (state: RootState) => {
  const day = state.activity.days.find((d: SetOfActivitiesForDay) => { return ( d.dateStr === dayStr)});
  if (typeof(day) === 'undefined' || day?.activities.length === 0) {
    return null;
  }

  const a = day.activities.reduce((prev, curr) => (curr.endTime > prev.endTime ? curr : prev));
  
  return(a);
}

export const getActivityDomainForId = (id: number) => (state: RootState) => {
  const d = state.activity.domains.domains.find((d: ActivityDomain) => { return ( d.id === id)});
  
  return(d);
}

export const getRootActivityDomainNameForId = (id: number) => (state: RootState) => {
  let d = getActivityDomainForId(id)(state);
  while (d && typeof(d.parentId) === 'number') {
    d = getActivityDomainForId(d.parentId)(state);
  }
  
  return(d);
}

export const getActivityDomainsForSelect = (state: RootState) => {
  const ds = state.activity.domains.domains.map((d: ActivityDomain) => { return { value: d.id.toString(), label: d.name }});
  
  return(ds);
}

export const { 
} = activitySlice.actions;
export default activitySlice.reducer