const origin = document.location.origin
const host = document.location.host

const isProduction = process.env.NODE_ENV === 'production'
const isLocal = !isProduction 

const domain = isLocal ? 'lvh.me' : 'jhn.frl'

const websiteRoot = isLocal ? `http://${domain}:3001` : `https://tt.${domain}/`

const apiRoot   = isLocal ? `http://pai.${domain}:3000` : `https://pai.${domain}/`
const signInUrl = `${websiteRoot}/auth`

const appName = "Time Tracker"

export const config = {
  env: isProduction ? 'production' : 'development',
  origin: origin,
  apiRoot: apiRoot,
  websiteRoot: websiteRoot,
  appName: appName,
  signInUrl: signInUrl,
}

export default config