import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../app/hooks'; 

import { Activity, ActivityDomain, RestEndpointState } from '../../_types';

import { parseDateStr } from '../../util/dateTimeFormatting';
import Select from 'react-select';
import { SingleValue, ActionMeta } from 'react-select';

import { 
  submitActivity,
  getActivityDomainsForSelect,
  getMostRecentActivityForDay
} from '../activities/activitySlice';

import { closePopUp } from '../uiState/uiSlice';

import { formatTime24HourClock } from '../../util/dateTimeFormatting';

export const NewActivityPopUp = () => {
  const dispatch = useAppDispatch();
  
  const firstFormFieldRef = useRef<HTMLInputElement>(null);
  // const firstFormFieldRef = useRef<StateManagedSelect>(null);
  const activeDateStr = useAppSelector((state) => state.ui.currentDateStr);

  const dropshadowStyle = "drop-shadow(2px 2px 12px #999)";

  const domainsForSelect = useAppSelector(getActivityDomainsForSelect);
  const mrafd = useAppSelector(getMostRecentActivityForDay(activeDateStr as string));

  const curTime = new Date();
  const prevTime = mrafd?.endTime ? new Date((mrafd.endTime as number) * 1000 ) : curTime;
  
  const [startTime, setStartTime] = useState(prevTime.getTime());
  const [startTimeStr, setStartTimeStr] = useState(formatTime24HourClock(prevTime));
  const [endTime, setEndTime] = useState(curTime.getTime());
  const [endTimeStr, setEndTimeStr] = useState(formatTime24HourClock(curTime));
  
  const [domainId, setDomainId] = useState('');

  const handleStartTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const t = parseDateStr(activeDateStr, val);
    setStartTime(t.getTime());
    setStartTimeStr(val);
  }
  const handleEndTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const t = parseDateStr(activeDateStr, val);
    setEndTime(t.getTime());
    setEndTimeStr(val);
  }

  const handleBgClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(closePopUp());
  }
  const handleNestedClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }

  // TODO
  const handleSelectChange: (
    newValue: SingleValue<{ value: string; label: string; }>, 
    actionMeta: ActionMeta<{ value: string; label: string; }>
  ) => void = (selected, actionMeta) => {
    if (selected) {
      setDomainId(selected.value)
    } else {
      setDomainId('')
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const activityDomainId = parseInt(domainId);
    console.log('submit');
    dispatch(submitActivity({
      activityDomainId: activityDomainId,
      startTime: startTime,
      endTime: endTime,
      day: activeDateStr as string 
    }));
    dispatch(closePopUp());
  }

  return (
    <div className='fixed inset-0 flex justify-center items-center' onClick={handleBgClick}>
      <div className='bg-gray-0 p-12' style={{filter: dropshadowStyle}} onClick={handleNestedClick}>
        <div className='mb-4'>
          <h2 className='text-xl'>Log new activity</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='mt-4 text-left'>
            <label htmlFor="activityStartTime" className='block mb-1'>Activity</label>
            <Select 
                options={domainsForSelect}
                isClearable={true} 
                onChange={handleSelectChange} 
                menuPlacement={'bottom'}
              />
          </div>

          <div className='flex'>
            <div className='m-4 ml-0 text-left'>
              <label htmlFor="activityStartTime" className='block'>Start time</label>
              <input 
                type="time" 
                min="00:00" 
                max="24:00" 
                value={startTimeStr} 
                onChange={handleStartTimeChanged} 
                required
                ref={firstFormFieldRef} 
              />
            </div>
            <div className='m-4 mr-0 text-left'>
              <label htmlFor="activityEndTime" className='block'>End time</label>
              <input type="time" min="00:00" max="24:00" value={endTimeStr} onChange={handleEndTimeChanged} required />
            </div>
          </div>
          
          <div className='my-4'>
            <input 
              type="submit" 
              name="commit"
              className="btn btn-border w-full py-3" 
              value="Create Journal activity" 
              // disabled={true} 
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewActivityPopUp;